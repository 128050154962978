.second-block,.portfolio-block{
  .top-gray-block{
    padding: 35px 0;
    .top-block-foot{
      display: flex;
      justify-content: space-between;
      .figure{
        position: relative;
        width: 50%;
        height: 75px;
        border-radius: 0 0 40px 40px;
        background-color: var(--cl-gray-block);
        top: 140%;
        left: 0;
        &::before{
          content: "";
          position: absolute;
          display: block;
          height: 37px;
          width: 37px;
          background-color: var(--cl-gray-block);
          top: 0px;
          left: 100%;
          z-index: 2;
        }
        &:after{
          content: "";
          position: absolute;
          z-index: 3;
          width: 74px;
          height: 74px;
          display: block;
          border-radius: 50%;
          background-color: #fff;
          top: 0;
          left: 100%;
        }
      }
      .top-btns-block{
        width: calc(50% - 15px);
        margin: 15px 0 0 15px;
        .btn-link{
          position: relative;
          z-index: 3;
          margin-bottom: 10px;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
          text-align: center;
          background-color: var(--cl-head-block);
        }
      }
    }
    .top-block-content{
      width: 100%;
      position: relative;
      background-color: var(--cl-gray-block);
      border-radius: 40px 40px 40px 0px;
      padding: 40px;
      display: flex;
      .first{
        width: 50%;
        padding-right: 30px;
        .slider{
          width: 100%;
          margin-bottom: -30px;
          &.slider-tab{
            display: none;
            &.active{
              display: block;
            }
          }
          .slides{
            margin-bottom: 0;
            width: 100%;
            .slide{

              width: 100%;
              .photo{
                border-radius: 35px;
                width: 100%;
                overflow: hidden;
                img{
                  display: block;
                  width: inherit;
                  height: inherit;
                  object-fit: contain;
                  object-position: center;
                }
              }
            }
          }
          .control{
            width: 100%;
            position: absolute;
            z-index: 5;
            top: calc(100% + 30px);
            .slick-dots{
              position: relative;
              bottom: unset;
              li{
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.3);
                width: 15px;
                height: 15px;
                button{
                  display: none;
                }
                &.slick-active{
                  background-color: rgba(0, 0, 0, 0.5);
                }
              }
            }

          }
        }
      }
      .second{
        width: 50%;
        padding-left: 15px;
        h1{
          font-size: 32px;
          line-height: 1.25;
          margin-bottom: 30px;
          span{
            font-weight: 400;
          }
        }
        .lists{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .list-1{
            ul{
              li{
                font-size: 18px;
                margin-bottom: 10px;
                &.tab-btn{
                  cursor: pointer;
                }
                &.active{
                  font-weight: 700;
                }
              }
            }
          }
          .list-2{
            ul{
              display: flex;
              flex-direction: column;
              li{
                margin: 8px 0;
                a{
                  display: block;
                  width: 40px;
                  height: 40px;
                  img{
                    width: inherit;
                    height: inherit;
                    display: block;
                  }

                }
              }
            }
          }
        }
      }
    }
    .top-btns-block{
      display: flex;
      justify-content: flex-end;
      margin: 15px 0 0 15px;
      .btn-link{
        margin-bottom: 10px;
        width: calc(50% - 15px);
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        text-align: center;
        background-color: var(--cl-head-block);
      }
    }
  }
  .text-1{
    font-size: 20px;
    margin-bottom: 0;
    span{
      text-transform: uppercase;
    }
  }
  .text-2{
    font-size: 20px;
  }
  .portfolio-list{
    margin: 25px 0 75px;
    display: none;
    &.active{
      display: block;
    }
    .portfolio-item{
      margin-bottom: 12px;
      width: 100%;
      display: block;
      img{
        width: 100%;
        object-fit: contain;
        object-position: center;
        display: block;

      }
    }
  }
}


@media screen and (max-width: 991px){
  .second-block,.portfolio-block{
    .top-gray-block{
      padding: 35px 0;
      .top-block-foot{
        display: flex;
        justify-content: space-between;
        .figure{
          position: relative;
          width: 50%;
          height: 65px;
          border-radius: 0 0 30px 30px;
          background-color: var(--cl-gray-block);
          top: 140%;
          left: 0;
          &::before{
            content: "";
            position: absolute;
            display: block;
            height: 37px;
            width: 37px;
            background-color: var(--cl-gray-block);
            top: 0px;
            left: 100%;
            z-index: 2;
          }
          &:after{
            content: "";
            position: absolute;
            z-index: 3;
            width: 74px;
            height: 74px;
            display: block;
            border-radius: 50%;
            background-color: #fff;
            top: 0;
            left: 100%;
          }
        }
        .top-btns-block{
          width: calc(50% - 15px);
          margin: 15px 0 0 15px;
          .btn-link{
            position: relative;
            z-index: 3;
            margin-bottom: 10px;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
            text-align: center;
            background-color: var(--cl-head-block);
          }
        }
      }
      .top-block-content{
        width: 100%;
        position: relative;
        background-color: var(--cl-gray-block);
        border-radius: 30px 30px 30px 0px;
        padding: 20px;
        display: flex;
        .first{
          width: 50%;
          padding-right: 30px;
          .slider{
            width: 100%;
            margin-bottom: -30px;
            .slides{
              margin-bottom: 0;
              width: 100%;
              .slide{

                width: 100%;
                .photo{
                  border-radius: 35px;
                  width: 100%;
                  overflow: hidden;
                  img{
                    display: block;
                    width: inherit;
                    height: inherit;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
            }
            .control{
              width: 100%;
              position: absolute;
              z-index: 5;
              top: calc(100% + 10px);
              .slick-dots{
                position: relative;
                bottom: unset;
                li{
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                  width: 15px;
                  height: 15px;
                  button{
                    display: none;
                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }

            }
          }
        }
        .second{
          width: 50%;
          padding-left: 15px;
          h1{
            font-size: 22px;
            line-height: 1.25;
            margin-bottom: 20px;
            span{
              font-weight: 400;
            }
          }
          .lists{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-1{
              ul{
                li{
                  font-size: 16px;
                  line-height: 1;
                  margin-bottom: 7px;
                }
              }
            }
            .list-2{
              ul{
                display: flex;
                flex-direction: column;
                li{
                  margin: 8px 0;
                  a{
                    display: block;
                    width: 40px;
                    height: 40px;
                    img{
                      width: inherit;
                      height: inherit;
                      display: block;
                    }

                  }
                }
              }
            }
          }
        }
      }
      .top-btns-block{
        display: flex;
        justify-content: flex-end;
        margin: 15px 0 0 15px;
        .btn-link{
          margin-bottom: 10px;
          width: calc(50% - 15px);
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
          text-align: center;
          background-color: var(--cl-head-block);
        }
      }
    }
    .text-1{
      font-size: 16px;
      margin-bottom: 0;
      span{
        text-transform: uppercase;
      }
    }
    .text-2{
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 767px){
  .second-block, .portfolio-block{
    .top-gray-block{
      padding: 25px 0;
      .top-block-foot{
        display: flex;
        justify-content: space-between;
        .figure{
          position: relative;
          width: 50%;
          height: 65px;
          border-radius: 0 0 25px 25px;
          background-color: var(--cl-gray-block);
          top: 140%;
          left: 0;
          &::before{
            content: "";
            position: absolute;
            display: block;
            height: 37px;
            width: 37px;
            background-color: var(--cl-gray-block);
            top: 0px;
            left: 100%;
            z-index: 2;
          }
          &:after{
            content: "";
            position: absolute;
            z-index: 3;
            width: 74px;
            height: 74px;
            display: block;
            border-radius: 50%;
            background-color: #fff;
            top: 0;
            left: 100%;
          }
        }
        .top-btns-block{
          width: calc(50% - 15px);
          margin: 15px 0 0 15px;
          .btn-link{
            position: relative;
            z-index: 3;
            margin-bottom: 10px;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
            text-align: center;
            background-color: var(--cl-head-block);
          }
        }
      }
      .top-block-content{
        width: 100%;
        position: relative;
        background-color: var(--cl-gray-block);
        border-radius: 25px 25px 25px 0px;
        padding: 20px 20px 10px;
        display: flex;
        .first{
          width: 50%;
          padding-right: 30px;
          .slider{
            width: 100%;
            margin-bottom: -30px;
            .slides{
              margin-bottom: 0;
              width: 100%;
              .slide{

                width: 100%;
                .photo{
                  border-radius: 20px;
                  width: 100%;
                  overflow: hidden;
                  img{
                    display: block;
                    width: inherit;
                    height: inherit;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
            }
            .control{
              width: 100%;
              position: absolute;
              z-index: 5;
              top: calc(100% + 10px);
              .slick-dots{
                position: relative;
                bottom: unset;
                line-height: 1;
                li{
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                  width: 8px;
                  height: 8px;
                  button{
                    display: none;
                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }

            }
          }
        }
        .second{
          width: 50%;
          padding-left: 0px;
          h1{
            font-size: 18px;
            line-height: 1.25;
            margin-bottom: 5px;
            span{
              font-weight: 400;
            }
          }
          .lists{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-1{
              ul{
                li{
                  font-size: 14px;
                  margin-bottom: 3px;
                }
              }
            }
            .list-2{
              ul{
                display: flex;
                flex-direction: column;
                li{
                  margin: 8px 0;
                  a{
                    display: block;
                    width: 30px;
                    height: 30px;
                    img{
                      width: inherit;
                      height: inherit;
                      display: block;
                    }

                  }
                }
              }
            }
          }
        }
      }
      .top-btns-block{
        display: flex;
        justify-content: flex-end;
        margin: 15px 0 0 15px;
        .btn-link{
          margin-bottom: 10px;
          width: calc(50% - 15px);
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
          text-align: center;
          background-color: var(--cl-head-block);
        }
      }
    }
    .text-1{
      font-size: 14px;
      margin-bottom: 0;
      span{
        text-transform: uppercase;
      }
    }
    .text-2{
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 575px){
  .second-block, .portfolio-block{
    .top-gray-block{
      padding: 25px 0;
      .top-block-foot{
        display: flex;
        justify-content: flex-end;
        .figure{
          display: none;
        }
        .top-btns-block{
          width: 50%;
          margin: -20px 0 0 0;
          .btn-link{
            position: relative;
            z-index: 3;
            margin-bottom: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
            text-align: center;
            background-color: var(--cl-head-block);
          }
        }
      }
      .top-block-content{
        width: 100%;
        position: relative;
        background-color: var(--cl-gray-block);
        border-radius: 25px;
        padding: 15px 15px 35px;
        display: flex;
        flex-wrap: wrap;
        .first{
          order:1;
          width: 100%;
          padding-right: 80px;
          .slider{
            width: 100%;
            margin-bottom: 0px;
            .slides{
              margin-bottom: 0;
              width: 100%;
              .slide{

                width: 100%;
                .photo{
                  border-radius: 30px;
                  width: 100%;
                  overflow: hidden;
                  img{
                    display: block;
                    width: inherit;
                    height: inherit;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
            }
            .control{
              width: 20%;
              position: absolute;
              z-index: 5;
              top: 0%;
              left: calc(100% + 20px);
              height: 100%;
              .slick-dots{
                height: 100%;
                position: relative;
                bottom: unset;
                flex-direction: column;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                li{
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                  line-height: 1;
                  width: 12px;
                  height: 12px;
                  margin: 5px 0;
                  flex-shrink: 0;
                  button{
                    display: none;
                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }

            }
          }
        }
        .second{
          order: 0;
          width: 100%;
          padding-left: 0px;
          margin-bottom: 20px;
          h1{
            font-size: 18px;
            line-height: 1.25;
            margin-bottom: 5px;
            span{
              font-weight: 400;
            }
          }
          .lists{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-1{
              ul{
                li{
                  font-size: 14px;
                  margin-bottom: 3px;
                }
              }
            }
            .list-2{
              ul{
                display: flex;
                flex-direction: column;
                li{
                  margin: 8px 0;
                  a{
                    display: block;
                    width: 30px;
                    height: 30px;
                    img{
                      width: inherit;
                      height: inherit;
                      display: block;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
    .text-1{
      font-size: 12px;
      margin-bottom: 0;
      span{
        text-transform: uppercase;
      }
    }
    .text-2{
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 399px){
  .second-block, .portfolio-block{
    .top-gray-block{
      padding: 25px 0;
      .top-block-foot{
        display: flex;
        justify-content: flex-end;
        .figure{
          display: none;
        }
        .top-btns-block{
          width: 60%;
          margin: -20px 0 0 0;
          .btn-link{
            position: relative;
            z-index: 3;
            margin-bottom: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fff;
            text-align: center;
            background-color: var(--cl-head-block);
          }
        }
      }
      .top-block-content{
        width: 100%;
        position: relative;
        background-color: var(--cl-gray-block);
        border-radius: 25px;
        padding: 15px 15px 35px;
        display: flex;
        flex-wrap: wrap;
        .first{
          order:1;
          width: 100%;
          padding-right: 80px;
          .slider{
            width: 100%;
            margin-bottom: 0px;
            .slides{
              margin-bottom: 0;
              width: 100%;
              .slide{

                width: 100%;
                .photo{
                  border-radius: 30px;
                  width: 100%;
                  overflow: hidden;
                  img{
                    display: block;
                    width: inherit;
                    height: inherit;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
            }
            .control{
              width: 20%;
              position: absolute;
              z-index: 5;
              top: 00%;
              left: calc(100% + 20px);
              height: 100%;
              .slick-dots{
                position: relative;
                bottom: unset;
                flex-direction: column;
                display: flex;
                height: 100%;
                flex-wrap: wrap;
                li{
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                  width: 10px;
                  height: 10px;
                  margin: 5px;
                  button{
                    display: none;
                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }

            }
          }
        }
        .second{
          order: 0;
          width: 100%;
          padding-left: 0px;
          margin-bottom: 20px;
          h1{
            font-size: 18px;
            line-height: 1.25;
            margin-bottom: 5px;
            span{
              font-weight: 400;
            }
          }
          .lists{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-1{
              ul{
                li{
                  font-size: 14px;
                  margin-bottom: 3px;
                }
              }
            }
            .list-2{
              ul{
                display: flex;
                flex-direction: column;
                li{
                  margin: 8px 0;
                  a{
                    display: block;
                    width: 30px;
                    height: 30px;
                    img{
                      width: inherit;
                      height: inherit;
                      display: block;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}