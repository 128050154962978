:root {
    --cl-bg-page: #ffffff;
    --cl-bg-footer: #e6e7e8;
    --cl-gray-block: #d1d3d4;
    --cl-head-block: #414042;
    --cl-button: #414042;
    --cl-button-text: #ffffff;
    --cl-dot: #939598;
    --cl-active-dot: #58595b;





  }