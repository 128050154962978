.contacts-block{
  padding: 25px 0 50px;
  .gray-form{
    padding: 40px 30px 30px;
    background-color: var(--cl-gray-block);
    border-radius: 40px;
    display: flex;
    form{
      width: 65%;
      display: flex;
      flex-direction: column;
      input{
        margin-bottom: 8px;
        width: 100%;
        border: 1px solid #fff ;
        padding: 12px;
        font-size: 14px;
        height: 50px;
        &::placeholder,&:placeholder-shown{
          opacity: 1;
          color: #000;
        }
      }
      textarea{
        border: 1px solid #fff ;
        font-size: 14px;
        margin-bottom: 25px;
        width: 100%;
        padding: 12px;
        &::placeholder,&:placeholder-shown{
          opacity: 1;
          color: #000;
        }
      }
      button{
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--cl-head-block);
        color: #fff;
        width: 55%;
        height: 50px;
        border-radius: 25px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .info{
      position: relative;
      width: 35%;
      padding-left: 20px;
      &::before{
        content: '';
        position: absolute;
        display: block;
        width: 50%;
        border-radius: 25px;
        background-color: #414042;
        height: 50px;
        z-index: 2;
        right: 0;
        top: -25px;
      }
      &::after{
        content: '';
        position: absolute;
        display: block;
        width: 50px;
        border-radius: 25px;
        background-color: #414042;
        height: 50px;
        z-index: 2;
        right: 0;
        top: 50px;
      }
      h2{
        border-radius: 25px;
        border: 2px solid #414042;
        width: 80%;
        text-align: center;
        font-size: 20px;
        padding: 8px 20px;
        background-color: #fff;
        position: relative;
        z-index: 3;
        margin-bottom: 25px;
      }
      .links{
        border-radius: 25px;
        border: 2px solid #414042;
        position: relative;
        background-color: #fff;
        z-index: 1;
        padding: 25px 30px;
        display: flex;
        flex-direction: column;
        .text-info{
          display: flex;
          flex-direction: column;
          .phone{
            height: 18px;
            margin-bottom: 9px;
            display: flex;
            align-items: center;
            margin-right: 5px;
            .icon{

            }
            .text{
              font-size: 14px;
              line-height: 1;
            }

          }
          .mail{
            margin-bottom: 9px;
            display: flex;
            align-items: center;
            margin-right: 5px;
            height: 18px;
            .icon{
              height: 18px;
              img{
                width: 100%;
                display: block;
                object-fit: contain;
                object-position: center;
              }
            }
            .text{
              font-size: 14px;
              line-height: 1;
            }

          }
          .address{
            margin-bottom: 9px;
            font-size: 14px;
            line-height: 1;
            height: 18px;
          }
          .site{
            height: 18px;
            margin-bottom: 9px;
            font-size: 14px;
            line-height: 1;
          }
        }
        .qr{
          margin-top: 17px;
          width: 80%;
          img{
            width: 100%;
            display: block;
            object-fit: contain;
            object-position: center;

          }
        }
      }
    }
   }
  .social{
    margin-top: 30px;
    width: 100%;
    ul{
      display: flex;
      align-items: center;
      margin: 0 -10px;
      width: 100%;
      li{
        margin: 0 10px;
        a{
          display: block;
          width: 50px;
          height: 50px;
          img{
            width: inherit;
            height: inherit;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px){
  .contacts-block{
    .gray-form{
      form{
        width: 50%;
        button{
          width: 100%;
        }
      }
      .info{
        width: 50%;
      }
    }
    .social{
      ul{
        margin: 0;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .contacts-block {
    .gray-form {
      padding: 35px 15px 15px;
      flex-wrap: wrap;
      border-radius: 25px;

      form {
        order: 1;
        width: 100%;

        button {
          width: 100%;
        }
      }

      .info {
        order: 0;
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
        .links{
          padding: 25px 15px 15px;
          flex-direction: row;
          flex-wrap: wrap;
          .text-info{
            width: 50%;
          }
          .qr{
            margin-top: 0;
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .contacts-block {
    .gray-form {
      padding: 35px 15px 15px;
      flex-wrap: wrap;

      form {
        order: 1;
        width: 100%;
        input{
          height: 40px;
          font-size: 12px;
        }

        button {
          width: 100%;
          height: 40px;
          font-size: 12px;
        }
        textarea{
          font-size: 12px;
        }
      }

      .info {
        order: 0;
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
        &::before{
          height: 40px;
          top: -20px;
        }
        &::after{
          height: 40px;
          width: 40px;
          border-radius: 20px;
        }
        .links{
          padding: 25px 15px 15px;
          flex-direction: row;
          flex-wrap: wrap;
          .text-info{
            width: 50%;
            .phone{
              .text{
                font-size: 12px;
              }
            }
            .mail{
              .text{
                font-size: 12px;
              }
            }
            .address{
              font-size: 12px;
            }
            .site{
                font-size: 12px;
            }
          }
          .qr{
            margin-top: 0;
            width: 50%;
          }
        }
        h2{
          height: 40px;
          font-size: 12px;
          border-radius: 20px;
          text-transform: uppercase;
        }
      }
    }
  }
}
@media screen and (max-width: 399px){
  .contacts-block {
    .gray-form {
      .info {

        .links{
          .text-info{
            width: 70%;

          }
          .qr{
            width: 30%;
          }
        }
      }
    }
  }
}