@import "variables";


input, textarea {outline:none;}
input:active, textarea:active {outline:none;}
:focus {outline:none;}
textarea {resize:none;}
textarea {resize:vertical;}
textarea {resize:horizontal;}


body{
  background-color: #ffffff;
  position: relative;
  min-height: 100vh;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  *{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    position: relative;
    color: var(--cl-text);
  }

  a{
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  &.menu-open{
    overflow-y: hidden;
    header{
      box-shadow: unset;
      .mobile-menu-icon {
        .icon-menu {
          .icon-block{
            justify-content: center;
            background-color: #414042;
            &:nth-child(1){
              position: relative;
              top: calc(50% - 2px);
              transform-origin: center;
              transform: rotate(45deg);
            }
            &:nth-child(2){
              opacity: 0;
            }
            &:nth-child(3){
              position: relative;
              bottom: calc(50% - 2px);
              transform-origin: center;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .mobile-menu{
      right: 0;
      .mobile-menu-container{
        &::before{
          opacity: 1;
          transition: opacity ease .4s .4s;
        }
      }
    }
  }
}
a{
  text-decoration: none;
}
ul,ol{
  padding: 0;
  margin: 0;
  list-style: none;
}
footer{
  background-color: var(--cl-bg-footer);
}
.gray-block{
  padding: 35px 0;
  .gray-block-head{
    position: relative;
    height: 75px;
    background-color: #fff;
    border-radius: 0 0 40px 0;
    z-index: 2;
    display: flex;
    bottom: -1px;

    .name-block {
      width: 70%;
      margin-bottom: 15px;
      margin-right: 15px;
      background-color: var(--cl-head-block);
      color: #fff;
      border-radius: 30px;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      text-transform: uppercase;
      font-size: 20px;
      position: relative;
      z-index: 4;
    }
    .figure-block{

      display: block;
      width: calc(30% - 15px);
      background-color: var(--cl-gray-block);
      border-radius: 40px 40px 0 0;
      height: 75px;
      right: 0;
      z-index: 2;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        display: block;
        height: 37px;
        width: 37px;
        background-color: var(--cl-gray-block);
        bottom:0px;
        right:100%;
        z-index: 2;
      }
      &:after{
        content: '';
        position: absolute;
        z-index: 3;
        width: 74px;
        height: 74px;
        display: block;
        border-radius: 50%;
        background-color: #fff;
        bottom: 0;
        right:100%;
      }
    }

  }
  .gray-block-body{
    position: relative;
    z-index: 5;
    padding: 30px;
    background-color: var(--cl-gray-block);
    border-radius: 40px 0 40px 40px;
    .block-content{
      display: flex;
      position: static;
      &.horizontal-content{
        .first{
          width: 70%;
          .block-list{
            padding-right: 90px;
            ul{
              li{
                margin-bottom: 20px;
                .name{
                  font-weight: 700;
                  text-transform: uppercase;
                }
                .desc{

                }
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .second{
          width: 30%;
          .block-slider{
            position: relative;
            padding-left: 60px;
            top: -60px;
            .slides{
              margin-bottom: -35px;
              margin-top: 25px;
              .slide{
                margin: 0 auto;
                width: 200px;
                height: 200px;
                .photo{
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  img{
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: contain;
                    object-position: center;

                  }
                }
              }
            }
            .control{
              width: calc(100% - 65px);
              bottom:calc(100% + 15px);
              position: absolute;
              .slick-dots{
                position: relative;
                bottom: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                li{
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  background-color: rgba(0, 0, 0, 0.3);
                  button{
                    &:before{
                      display: none;
                    }

                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }
            }
          }
        }
      }
      &.vertical-content{
        flex-direction: column;
        .first{
          .block-list{
            ul{
              li{
                margin-bottom: 20px;
                .name{
                  font-weight: 700;
                  text-transform: uppercase;
                }
                .desc{

                }
              }
            }
          }
        }
        .second{
          position: static;
          .block-slider{
            position: static;
            .slides{
              .slide{
                margin: 0 auto;
                width: 200px;
                height: 200px;
                .photo{
                  width: 100%;
                  height: 100%;
                  padding: 10px;
                  img{
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: contain;
                    object-position: center;

                  }
                }
              }
            }
            .control{
              width: calc(30% - 15px);
              bottom:calc(100% + 15px);
              position: absolute;
              right: -0px;
              .slick-dots{
                position: relative;
                bottom: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                li{
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  background-color: rgba(0, 0, 0, 0.3);
                  button{
                    &:before{
                      display: none;
                    }

                  }
                  &.slick-active{
                    background-color: rgba(0, 0, 0, 0.5);
                  }
                }
              }
            }
          }
        }
        .one{
          .block-list{
            ul{
              padding-left: 20px;
              margin-bottom: 20px;
              list-style: disc;
              li{
                font-size: 20px;
                margin-bottom: 5px;
              }
            }
          }
          .block-text{
            font-size: 20px;
            padding-right: 40%;
          }
        }
      }
    }

  }
  .gray-block-foot{
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;
    position: relative;
    z-index: 6;
    .btns-block{
      width: 50%;
      .btn-link{
        margin-bottom: 10px;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        text-align: center;
        background-color: var(--cl-head-block);
      }
    }
  }
}


@media screen and (max-width: 1399px){
  body{

  }

}
@media screen and (max-width: 1199px){
  body{

  }

}
@media screen and (max-width: 991px){
  body{
    padding-top: 50px;
  }
  .gray-block{
    .gray-block-head{
      height: 65px;
      border-radius: 0 0 30px 0;
      z-index: 2;
      display: flex;

      .name-block {
        border-radius: 30px;
        height: 50px;
        font-size: 16px;
      }
      .figure-block{
        border-radius: 30px 30px 0 0;
        height: 65px;
        &::before{
        }
        &:after{
        }
      }

    }
    .gray-block-body{
      padding: 20px;
      border-radius: 30px 0 30px 30px;
      .block-content{
        &.horizontal-content{
          .first{
            .block-list{
              padding-right: 20px;
              ul{
                li{
                  .name{
                    font-size: 14px;
                  }
                  .desc{
                    font-size: 14px;
                  }
                  &:last-child{
                    margin-bottom: 15px;
                  }

                }
              }
            }
          }
          .second{
            .block-slider{
              padding-left: 20px;
              top: -60px;
              left: 10px;
              .slides{
                .slide{
                  margin: 0 auto;
                  width: 180px;
                  height: 180px;
                }
              }
              .control{
                width: calc(100% - 15px);
                bottom:calc(100% + 10px);
                position: absolute;
                .slick-dots{
                  li{
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
        &.vertical-content{
          flex-direction: column;
          .first{
            .block-list{
              padding-right: 20px;
              ul{
                li{
                  .name{
                    font-size: 14px;
                  }
                  .desc{
                    font-size: 14px;
                  }
                  &:last-child{
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
          .second{
            position: static;
            .block-slider{
              position: static;
              .slides{
                .slide{
                  margin: 0 auto;
                  width: 180px;
                  height: 180px;
                }
              }
              .control{

                bottom:calc(100% + 25px);
                .slick-dots{
                  li{
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
          .one{
            .block-list{
              ul{
                li{
                  font-size: 16px;
                }
              }
            }
            .block-text{
              font-size: 16px;
              margin-bottom: 10px;
            }
          }
        }
      }

    }
    .gray-block-foot{
      .btns-block{
        .btn-link{
          height: 50px;
          font-size: 14px;
        }
      }
    }
  }

}

@media screen and (max-width: 767px){
  body{

  }
  .gray-block{
    padding: 25px 0;
    .gray-block-head{
      height: 65px;
      border-radius: 0 0 30px 0;
      z-index: 2;
      display: flex;

      .name-block {
        font-size: 14px;
        padding: 0 20px;
        width: 85%;
      }
      .figure-block{
        width: 15%;
        border-radius: 25px 25px 0 0;
        &::before{
        }
        &:after{
        }
      }

    }
    .gray-block-body{
      padding: 15px;
      border-radius: 25px 0 25px 25px;
      .block-content{
        z-index: 6;
        &.horizontal-content{
          flex-direction: column;
          .first{
            width: 100%;
            .block-list{
              padding-right: 0px;
              ul{
                li{
                  .name{
                    font-size: 14px;
                  }
                  .desc{
                    font-size: 14px;
                  }
                  &:last-child{
                    margin-bottom: 15px;
                  }

                }
              }
            }
          }
          .second{
            width: 100%;
            .block-slider{
              padding-left: 0px;
              margin-bottom: 20px;
              position: relative;
              top: 0px;
              left: 0px;
              .slides{
                margin-top: 0;
                margin-bottom: 0;
                .slide{
                  width: 150px;
                  height: 150px;
                }
              }
              .control{
                width: 30%;
                bottom:-10px;
                position: absolute;
                z-index: 6;
                .slick-dots{
                  justify-content: flex-start;
                  li{
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
        &.vertical-content{
          flex-direction: column;
          .first{
            .block-list{
              padding-right: 0px;
              ul{
                margin-bottom: 10px;
                li{
                  .name{

                  }
                  .desc{

                  }
                  &:last-child{

                  }
                }
              }
            }
          }
          .second{
            position: static;
            .block-slider{
              position: relative;
              .slides{
                margin-bottom: 30px;
                .slide{
                  margin: 0 auto;
                  width: 150px;
                  height: 150px;
                }
              }
              .control{

                bottom: -15px;
                left: 0;
                .slick-dots{
                  justify-content: flex-start;
                  li{
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
          .one{
            .block-list{
              ul{
                li{
                  font-size: 14px;
                }
              }
            }
            .block-text{
              font-size: 14px;
              margin-bottom: 10px;
            }
          }
        }
      }

    }
    .gray-block-foot{
      .btns-block{
        .btn-link{
          height: 50px;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  body {
    .gray-block{
      padding: 20px 0;
      .gray-block-head{
        height: 55px;
        border-radius: 0 0 25px 0;

        .name-block {
          font-size: 12px;
          padding: 0 15px;
          width: 85%;
          height: 40px;
          border-radius: 20px;
        }
        .figure-block{
          width: 15%;
          height: 55px;
          border-radius: 20px 20px 0 0;

        }

      }
      .gray-block-body{
        padding: 15px;
        border-radius: 25px 0 25px 25px;
        .block-content{
          z-index: 6;
          &.horizontal-content{
            flex-direction: column;
            .first{
              width: 100%;
              .block-list{
                padding-right: 0px;
                ul{
                  li{
                    margin-bottom: 10px;
                    .name{
                      font-size: 12px;
                    }
                    .desc{
                      font-size: 12px;
                    }
                    &:last-child{
                      margin-bottom: 10px;
                    }

                  }
                }
              }
            }
            .second{
              width: 100%;
              .block-slider{
                padding-left: 0px;
                margin-bottom: 20px;
                position: relative;
                top: 0px;
                left: 0px;
                .slides{
                  margin-top: 0;
                  margin-bottom: 0;
                  .slide{
                    width: 150px;
                    height: 150px;
                  }
                }
                .control{
                  width: 30%;
                  bottom:-10px;
                  position: absolute;
                  z-index: 6;
                  .slick-dots{
                    justify-content: flex-start;
                    li{
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }
            }
          }
          &.vertical-content{
            flex-direction: column;
            .first{
              .block-list{
                padding-right: 0px;
                ul{
                  margin-bottom: 10px;
                  li{
                    .name{
                      font-size: 12px;
                    }
                    .desc{
                      font-size: 12px;
                    }
                    &:last-child{

                    }
                  }
                }
              }
            }
            .second{
              position: static;
              .block-slider{
                position: relative;
                .slides{
                  margin-bottom: 30px;
                  .slide{
                    margin: 0 auto;
                    width: 150px;
                    height: 150px;
                  }
                }
                .control{

                  bottom: -15px;
                  left: 0;
                  .slick-dots{
                    justify-content: flex-start;
                    li{
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }
            }
            .one{
              .block-list{
                ul{
                  padding-left: 10px;
                  li{
                    font-size: 12px;
                  }
                }
              }
              .block-text{
                padding-right: 0;
                font-size: 12px;
                margin-bottom: 10px;
              }
            }
          }
        }

      }
      .gray-block-foot{
        margin-top: -20px;
        .btns-block{
          .btn-link{
            height: 40px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 399px) {
  body {
    .gray-block{
      padding: 25px 0;
      .gray-block-head{
        height: 55px;
        border-radius: 0 0 25px 0;

        .name-block {
          font-size: 10px;
          padding: 0 15px;
          width: 85%;
          height: 40px;
          border-radius: 20px;
        }
        .figure-block{
          width: 15%;
          height: 55px;
          border-radius: 20px 20px 0 0;

        }

      }
      .gray-block-foot{
        margin-top: -20px;
        .btns-block{
          width: 60%;
          .btn-link{
            height: 40px;
            font-size: 10px;
          }
        }
      }
    }
  }
}
.fancybox-bg{
  background-color: rgba(36, 26, 18, 0.63);
}
.fancybox-content{
  padding: 0!important;
}
@import "components/btns";
@import "header";
@import "footer";
@import "price-block";
@import "modals";
@import "main-page/first-block";
@import "main-page/second-block";
@import "main-page/contacts-block";
