
.container-modal{
  position: relative;
  padding: 20px;
  display: block;
  width: 400px;
  height: 460px;
  .btn-close{
    position: absolute;
    top:16px;
    right: 16px;
    z-index: 1006;
    .icon-close{

      width: 26px;
      height: 26px;
      position: relative;
      cursor: pointer;
      .close-block{
        height: 2px;
        width: 34px;
        background-color: #212112;
        position: absolute;
        transition: ease .4s;
        &:nth-child(1){
          left:-4px;
          top:10px;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          right: -4px;
          top:10px;
          transform: rotate(-45deg);


        }
      }
    }
  }
  .head-modal{
    position: relative;
    padding-top: 0px;
    .name-modal{
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 15px;
      position: relative;
      z-index: 5;
    }
    .text-modal{
      position: relative;
      z-index: 5;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .body-modal{
    .container-input{
      margin-bottom: 10px;
      display: flex;
      position: relative;
      z-index: 5;
      align-items: center;
      height: 50px;
      border: 1px solid gray;
      background-color: #fff;
      padding: 0 12px;
      input{
        border: none;
        line-height: 1;
        height: 100%;
        width: 100%;
        padding: 12px 0 12px 10px;
        border-radius: 50px;
        font-size: 16px;
      }
    }
    .container-submit{
      position: relative;
      width: 100%;
      height: 50px;
      margin: 0 auto;
      .btn,.btn-modal-close{
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        margin: 0 auto;
        font-size: 14px;
        border:none;
        background-color: #414042;
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;


      }
    }
    .politics{
      margin-bottom: 20px;
      label{
        overflow: unset;
        display: flex;
        align-items: center;
        cursor: pointer;
        .square{
          width: 16px;
          height: 16px;
          border: 1px solid #414042;
          border-radius: 2px;
          margin-right: 10px;
          position: relative;
          transition: ease .4s;
          &::before{
            content: '';
            position: absolute;
            display: block;
            border: 1px solid #fff;
            border-left: 0;
            border-top: 0;
            width: 6px;
            height: 10px;
            transform: rotate(45deg);
            left: calc(50% - 3px);
            top: calc(50% - 6px)
          }
        }
        .text{
          font-size: 14px;
          line-height: 19px;
          color: #7C7C7C;
        }
        input{
          display: block;
          width: 0;
          height: 0;
          border: none;
          position: absolute;
          left: -999px;

        }
        &.checkbox-checked{
          .square{
            background-color: #414042;
          }
        }
      }
    }
  }
}
#modal-success, #modal-error{
  .container-modal{
    height: 196px;
  }
}

@media screen and (max-width: 767px){
  .container-modal{
    padding: 20px;
    display: block;
    width: 450px;
    height: 400px;
    .head-modal{
      padding-top: 10px;
      .name-modal{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
      }
      .text-modal{
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;
      }
      .figure{
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
      }
    }
    .body-modal{
      .container-input{
        height: 40px;
        margin-bottom: 10px;
        input{
          font-size: 14px;
          padding-left: 0;
        }
      }
      .politics{
        margin-bottom: 20px;
        label{
          .text{
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .container-submit{
        height: 40px;
          .figure{
            width: 60px;
            height: 60px;
            top: -10px;
            right: -20px;
          }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .container-modal{
    width: 100%;
    .btn-close{
      top: 10px;
      right: 10px;
      .icon-close{
        width: 18px;
        height: 18px;
        .close-block{
          height: 2px;
          width: 22px;
          &:nth-child(1){
            left: -2px;
          }
          &:nth-child(2){
            right: -2px;
          }
        }
      }
    }
  }
}
