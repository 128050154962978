:root {
  --cl-bg-page: #ffffff;
  --cl-bg-footer: #e6e7e8;
  --cl-gray-block: #d1d3d4;
  --cl-head-block: #414042;
  --cl-button: #414042;
  --cl-button-text: #ffffff;
  --cl-dot: #939598;
  --cl-active-dot: #58595b;
}

input, textarea {
  outline: none;
}

input:active, textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

body {
  background-color: #ffffff;
  position: relative;
  min-height: 100vh;
  display: flex;
  font-size: 16px;
  flex-direction: column;
}
body * {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  position: relative;
  color: var(--cl-text);
}
body a {
  text-decoration: none;
}
body a:hover {
  text-decoration: none;
}
body.menu-open {
  overflow-y: hidden;
}
body.menu-open header {
  box-shadow: unset;
}
body.menu-open header .mobile-menu-icon .icon-menu .icon-block {
  justify-content: center;
  background-color: #414042;
}
body.menu-open header .mobile-menu-icon .icon-menu .icon-block:nth-child(1) {
  position: relative;
  top: calc(50% - 2px);
  transform-origin: center;
  transform: rotate(45deg);
}
body.menu-open header .mobile-menu-icon .icon-menu .icon-block:nth-child(2) {
  opacity: 0;
}
body.menu-open header .mobile-menu-icon .icon-menu .icon-block:nth-child(3) {
  position: relative;
  bottom: calc(50% - 2px);
  transform-origin: center;
  transform: rotate(-45deg);
}
body.menu-open .mobile-menu {
  right: 0;
}
body.menu-open .mobile-menu .mobile-menu-container::before {
  opacity: 1;
  transition: opacity ease 0.4s 0.4s;
}

a {
  text-decoration: none;
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

footer {
  background-color: var(--cl-bg-footer);
}

.gray-block {
  padding: 35px 0;
}
.gray-block .gray-block-head {
  position: relative;
  height: 75px;
  background-color: #fff;
  border-radius: 0 0 40px 0;
  z-index: 2;
  display: flex;
  bottom: -1px;
}
.gray-block .gray-block-head .name-block {
  width: 70%;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: var(--cl-head-block);
  color: #fff;
  border-radius: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 20px;
  position: relative;
  z-index: 4;
}
.gray-block .gray-block-head .figure-block {
  display: block;
  width: calc(30% - 15px);
  background-color: var(--cl-gray-block);
  border-radius: 40px 40px 0 0;
  height: 75px;
  right: 0;
  z-index: 2;
  position: relative;
}
.gray-block .gray-block-head .figure-block::before {
  content: "";
  position: absolute;
  display: block;
  height: 37px;
  width: 37px;
  background-color: var(--cl-gray-block);
  bottom: 0px;
  right: 100%;
  z-index: 2;
}
.gray-block .gray-block-head .figure-block:after {
  content: "";
  position: absolute;
  z-index: 3;
  width: 74px;
  height: 74px;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  bottom: 0;
  right: 100%;
}
.gray-block .gray-block-body {
  position: relative;
  z-index: 5;
  padding: 30px;
  background-color: var(--cl-gray-block);
  border-radius: 40px 0 40px 40px;
}
.gray-block .gray-block-body .block-content {
  display: flex;
  position: static;
}
.gray-block .gray-block-body .block-content.horizontal-content .first {
  width: 70%;
}
.gray-block .gray-block-body .block-content.horizontal-content .first .block-list {
  padding-right: 90px;
}
.gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li {
  margin-bottom: 20px;
}
.gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .name {
  font-weight: 700;
  text-transform: uppercase;
}
.gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li:last-child {
  margin-bottom: 0;
}
.gray-block .gray-block-body .block-content.horizontal-content .second {
  width: 30%;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider {
  position: relative;
  padding-left: 60px;
  top: -60px;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides {
  margin-bottom: -35px;
  margin-top: 25px;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide {
  margin: 0 auto;
  width: 200px;
  height: 200px;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide .photo {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide .photo img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control {
  width: calc(100% - 65px);
  bottom: calc(100% + 15px);
  position: absolute;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots {
  position: relative;
  bottom: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li button:before {
  display: none;
}
.gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li.slick-active {
  background-color: rgba(0, 0, 0, 0.5);
}
.gray-block .gray-block-body .block-content.vertical-content {
  flex-direction: column;
}
.gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li {
  margin-bottom: 20px;
}
.gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li .name {
  font-weight: 700;
  text-transform: uppercase;
}
.gray-block .gray-block-body .block-content.vertical-content .second {
  position: static;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider {
  position: static;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide {
  margin: 0 auto;
  width: 200px;
  height: 200px;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide .photo {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide .photo img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control {
  width: calc(30% - 15px);
  bottom: calc(100% + 15px);
  position: absolute;
  right: 0px;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots {
  position: relative;
  bottom: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li button:before {
  display: none;
}
.gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li.slick-active {
  background-color: rgba(0, 0, 0, 0.5);
}
.gray-block .gray-block-body .block-content.vertical-content .one .block-list ul {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: disc;
}
.gray-block .gray-block-body .block-content.vertical-content .one .block-list ul li {
  font-size: 20px;
  margin-bottom: 5px;
}
.gray-block .gray-block-body .block-content.vertical-content .one .block-text {
  font-size: 20px;
  padding-right: 40%;
}
.gray-block .gray-block-foot {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  position: relative;
  z-index: 6;
}
.gray-block .gray-block-foot .btns-block {
  width: 50%;
}
.gray-block .gray-block-foot .btns-block .btn-link {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: var(--cl-head-block);
}

@media screen and (max-width: 991px) {
  body {
    padding-top: 50px;
  }

  .gray-block .gray-block-head {
    height: 65px;
    border-radius: 0 0 30px 0;
    z-index: 2;
    display: flex;
  }
  .gray-block .gray-block-head .name-block {
    border-radius: 30px;
    height: 50px;
    font-size: 16px;
  }
  .gray-block .gray-block-head .figure-block {
    border-radius: 30px 30px 0 0;
    height: 65px;
  }
  .gray-block .gray-block-body {
    padding: 20px;
    border-radius: 30px 0 30px 30px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list {
    padding-right: 20px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .name {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .desc {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li:last-child {
    margin-bottom: 15px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider {
    padding-left: 20px;
    top: -60px;
    left: 10px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide {
    margin: 0 auto;
    width: 180px;
    height: 180px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control {
    width: calc(100% - 15px);
    bottom: calc(100% + 10px);
    position: absolute;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li {
    width: 20px;
    height: 20px;
  }
  .gray-block .gray-block-body .block-content.vertical-content {
    flex-direction: column;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list {
    padding-right: 20px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li .name {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li .desc {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li:last-child {
    margin-bottom: 0px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second {
    position: static;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider {
    position: static;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide {
    margin: 0 auto;
    width: 180px;
    height: 180px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control {
    bottom: calc(100% + 25px);
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li {
    width: 20px;
    height: 20px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .one .block-list ul li {
    font-size: 16px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .one .block-text {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .gray-block .gray-block-foot .btns-block .btn-link {
    height: 50px;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .gray-block {
    padding: 25px 0;
  }
  .gray-block .gray-block-head {
    height: 65px;
    border-radius: 0 0 30px 0;
    z-index: 2;
    display: flex;
  }
  .gray-block .gray-block-head .name-block {
    font-size: 14px;
    padding: 0 20px;
    width: 85%;
  }
  .gray-block .gray-block-head .figure-block {
    width: 15%;
    border-radius: 25px 25px 0 0;
  }
  .gray-block .gray-block-body {
    padding: 15px;
    border-radius: 25px 0 25px 25px;
  }
  .gray-block .gray-block-body .block-content {
    z-index: 6;
  }
  .gray-block .gray-block-body .block-content.horizontal-content {
    flex-direction: column;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first {
    width: 100%;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list {
    padding-right: 0px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .name {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .desc {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li:last-child {
    margin-bottom: 15px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second {
    width: 100%;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider {
    padding-left: 0px;
    margin-bottom: 20px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides {
    margin-top: 0;
    margin-bottom: 0;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide {
    width: 150px;
    height: 150px;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control {
    width: 30%;
    bottom: -10px;
    position: absolute;
    z-index: 6;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots {
    justify-content: flex-start;
  }
  .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li {
    width: 20px;
    height: 20px;
  }
  .gray-block .gray-block-body .block-content.vertical-content {
    flex-direction: column;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list {
    padding-right: 0px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul {
    margin-bottom: 10px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second {
    position: static;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider {
    position: relative;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides {
    margin-bottom: 30px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide {
    margin: 0 auto;
    width: 150px;
    height: 150px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control {
    bottom: -15px;
    left: 0;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots {
    justify-content: flex-start;
  }
  .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li {
    width: 20px;
    height: 20px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .one .block-list ul li {
    font-size: 14px;
  }
  .gray-block .gray-block-body .block-content.vertical-content .one .block-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .gray-block .gray-block-foot .btns-block .btn-link {
    height: 50px;
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  body .gray-block {
    padding: 20px 0;
  }
  body .gray-block .gray-block-head {
    height: 55px;
    border-radius: 0 0 25px 0;
  }
  body .gray-block .gray-block-head .name-block {
    font-size: 12px;
    padding: 0 15px;
    width: 85%;
    height: 40px;
    border-radius: 20px;
  }
  body .gray-block .gray-block-head .figure-block {
    width: 15%;
    height: 55px;
    border-radius: 20px 20px 0 0;
  }
  body .gray-block .gray-block-body {
    padding: 15px;
    border-radius: 25px 0 25px 25px;
  }
  body .gray-block .gray-block-body .block-content {
    z-index: 6;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content {
    flex-direction: column;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first {
    width: 100%;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first .block-list {
    padding-right: 0px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li {
    margin-bottom: 10px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .name {
    font-size: 12px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li .desc {
    font-size: 12px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .first .block-list ul li:last-child {
    margin-bottom: 10px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second {
    width: 100%;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider {
    padding-left: 0px;
    margin-bottom: 20px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides {
    margin-top: 0;
    margin-bottom: 0;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .slides .slide {
    width: 150px;
    height: 150px;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control {
    width: 30%;
    bottom: -10px;
    position: absolute;
    z-index: 6;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots {
    justify-content: flex-start;
  }
  body .gray-block .gray-block-body .block-content.horizontal-content .second .block-slider .control .slick-dots li {
    width: 15px;
    height: 15px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content {
    flex-direction: column;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .first .block-list {
    padding-right: 0px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul {
    margin-bottom: 10px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li .name {
    font-size: 12px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .first .block-list ul li .desc {
    font-size: 12px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second {
    position: static;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider {
    position: relative;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides {
    margin-bottom: 30px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .slides .slide {
    margin: 0 auto;
    width: 150px;
    height: 150px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control {
    bottom: -15px;
    left: 0;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots {
    justify-content: flex-start;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .second .block-slider .control .slick-dots li {
    width: 15px;
    height: 15px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .one .block-list ul {
    padding-left: 10px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .one .block-list ul li {
    font-size: 12px;
  }
  body .gray-block .gray-block-body .block-content.vertical-content .one .block-text {
    padding-right: 0;
    font-size: 12px;
    margin-bottom: 10px;
  }
  body .gray-block .gray-block-foot {
    margin-top: -20px;
  }
  body .gray-block .gray-block-foot .btns-block .btn-link {
    height: 40px;
    font-size: 12px;
  }
}
@media screen and (max-width: 399px) {
  body .gray-block {
    padding: 25px 0;
  }
  body .gray-block .gray-block-head {
    height: 55px;
    border-radius: 0 0 25px 0;
  }
  body .gray-block .gray-block-head .name-block {
    font-size: 10px;
    padding: 0 15px;
    width: 85%;
    height: 40px;
    border-radius: 20px;
  }
  body .gray-block .gray-block-head .figure-block {
    width: 15%;
    height: 55px;
    border-radius: 20px 20px 0 0;
  }
  body .gray-block .gray-block-foot {
    margin-top: -20px;
  }
  body .gray-block .gray-block-foot .btns-block {
    width: 60%;
  }
  body .gray-block .gray-block-foot .btns-block .btn-link {
    height: 40px;
    font-size: 10px;
  }
}
.fancybox-bg {
  background-color: rgba(36, 26, 18, 0.63);
}

.fancybox-content {
  padding: 0 !important;
}

header {
  display: none;
  position: fixed;
  padding: 4px 0;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--cl-bg-page);
  box-shadow: 0px 3px 5px rgba(32, 74, 77, 0.1);
}
header .logo {
  margin-left: 6px;
}
header .logo img {
  height: 52px;
}
header .phone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
}
header .phone img {
  width: 52px;
  height: 52px;
  display: block;
}
header .mobile-menu-icon .icon-menu {
  margin: 11px 6px 11px 0;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 0;
}
header .mobile-menu-icon .icon-menu .icon-block {
  width: 100%;
  height: 4px;
  background-color: #000;
  border-radius: 6px;
  opacity: 1;
  transition: ease 0.4s;
  transform: rotate(0);
}
header .mobile-menu-icon .icon-menu.menu-open .icon-block {
  justify-content: center;
  background-color: #414042;
}
header .mobile-menu-icon .icon-menu.menu-open .icon-block:nth-child(1) {
  position: relative;
  top: calc(50% - 2px);
  transform-origin: center;
  transform: rotate(45deg);
}
header .mobile-menu-icon .icon-menu.menu-open .icon-block:nth-child(2) {
  opacity: 0;
}
header .mobile-menu-icon .icon-menu.menu-open .icon-block:nth-child(3) {
  position: relative;
  bottom: calc(50% - 2px);
  transform-origin: center;
  transform: rotate(-45deg);
}

.mobile-menu {
  top: 0;
  right: -100vw;
  position: fixed;
  z-index: 900;
  width: 320px;
  height: 100dvh;
  padding-top: 60px;
  transition: ease 0.4s;
}
.mobile-menu .mobile-menu-container {
  background-color: #fff;
  padding: 18px 6px 12px;
  top: -2px;
  position: relative;
  width: 100%;
  height: calc(100% + 2px);
  box-shadow: 0px 5px 5px rgba(32, 74, 77, 0.1);
}
.mobile-menu .mobile-menu-container::before {
  content: "";
  opacity: 0;
  position: absolute;
  width: calc(100dvw - 320px);
  display: block;
  height: 100dvh;
  right: 100%;
  top: 0;
  background-color: #1e1e1e;
}
.mobile-menu .mobile-menu-container .menu-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mobile-menu .mobile-menu-container .menu-content-container .menu-name {
  font-weight: 700;
  line-height: 1;
  padding-bottom: 12px;
  border-bottom: 2px solid #0000003d;
  margin-bottom: 6px;
}
.mobile-menu .mobile-menu-container .menu-content-container .menu-content {
  height: calc(100dvh - 28px - 30px - 50px - 14px);
  flex-grow: 1;
  overflow: hidden;
}
.mobile-menu .mobile-menu-container .menu-content-container .menu-content .menu-content-scroll {
  overflow: auto;
  width: calc(100% + 50px);
  padding-right: 50px;
  padding-left: 6px;
}
.mobile-menu .mobile-menu-container .menu-content-container .menu-control {
  margin-top: 6px;
  border-top: 2px solid #0000003d;
  height: 56px;
  overflow: hidden;
}
.mobile-menu .mobile-menu-container .menu-content-container .menu-control .btn-close {
  margin-top: 8px;
  cursor: pointer;
  width: 100%;
  height: 36px;
  border-radius: 20px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  header {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  header {
    padding: 2px 0;
  }
  header .logo img {
    height: 42px;
  }
  header .phone {
    margin-top: 3px;
    width: 38px;
    height: 38px;
  }
  header .phone img {
    width: 42px;
    height: 42px;
  }
  header .mobile-menu-icon .icon-menu {
    width: 26px;
    height: 26px;
    margin: 10px 6px 10px 0;
  }

  .mobile-menu {
    padding-top: 50px;
  }
  .mobile-menu .mobile-menu-container {
    padding: 12px 6px 6px;
  }
  .mobile-menu .mobile-menu-container .menu-content-container .menu-control {
    height: 56px;
  }
}
footer {
  margin-top: auto;
  padding: 25px 0;
}
footer .social {
  width: 100%;
}
footer .social ul {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  width: 100%;
}
footer .social ul li {
  margin: 0 10px;
}
footer .social ul li a {
  display: block;
  width: 50px;
  height: 50px;
}
footer .social ul li a img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 991px) {
  footer .social ul {
    margin: 0;
    justify-content: center;
  }
}
.price-block {
  padding: 100px 0 50px;
}
.price-block .price-gray-block .price-block-head {
  display: flex;
}
.price-block .price-gray-block .price-block-head .logo {
  width: calc(45% + 15px);
  position: relative;
  z-index: 3;
  margin: 0 15px 15px 0;
  padding-left: 100px;
}
.price-block .price-gray-block .price-block-head .logo img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}
.price-block .price-gray-block .price-block-head h1 {
  position: relative;
  border-radius: 35px 35px 0 0;
  background-color: #e6e7e8;
  padding: 50px 30px 30px;
  width: calc(55% - 15px);
  text-align: right;
  font-size: 26px;
  margin-bottom: 0;
}
.price-block .price-gray-block .price-block-head h1 span {
  text-transform: uppercase;
}
.price-block .price-gray-block .price-block-head h1::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  display: block;
  right: 100%;
  bottom: 0;
  background-color: #e6e7e8;
  z-index: 1;
}
.price-block .price-gray-block .price-block-head h1::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  display: block;
  right: 100%;
  border-radius: 50%;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}
.price-block .price-gray-block .price-block-content {
  border-radius: 35px 0 35px 35px;
  background-color: #e6e7e8;
  padding: 30px;
}
.price-block .price-gray-block .price-block-content .list {
  margin-bottom: 20px;
}
.price-block .price-gray-block .price-block-content .list .list-head {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 10px;
  position: relative;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li::before {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  border-bottom: 1px dotted #212529;
  bottom: 4px;
  left: 0;
  z-index: 1;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li .name {
  width: 60%;
  padding-right: 10px;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li .name span {
  position: relative;
  z-index: 2;
  display: inline;
  background-color: #e6e7e8;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li .price {
  width: 10%;
  text-align: right;
  white-space: nowrap;
}
.price-block .price-gray-block .price-block-content .list .list-body ul li .price span {
  position: relative;
  z-index: 2;
  background-color: #e6e7e8;
}
.price-block .price-gray-block .price-block-content .message {
  margin-top: 40px;
}
.price-block .price-gray-block .price-block-foot {
  display: flex;
  justify-content: flex-end;
}
.price-block .price-gray-block .price-block-foot .btns-block {
  width: 50%;
}
.price-block .price-gray-block .price-block-foot .btns-block .btn-link {
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: var(--cl-head-block);
  margin-top: -30px;
}

@media screen and (max-width: 991px) {
  .price-block {
    padding: 50px 0;
  }
  .price-block .price-gray-block .price-block-head h1 {
    padding: 20px;
    font-size: 22px;
  }
  .price-block .price-gray-block .price-block-content {
    padding: 20px;
  }
  .price-block .price-gray-block .price-block-content .list .list-head {
    font-size: 18px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .name {
    width: 80%;
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .price {
    width: 20%;
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content .message {
    margin-top: 40px;
  }
  .price-block .price-gray-block .price-block-content .message p {
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-foot {
    display: flex;
    justify-content: flex-end;
  }
  .price-block .price-gray-block .price-block-foot .btns-block {
    width: 50%;
  }
  .price-block .price-gray-block .price-block-foot .btns-block .btn-link {
    height: 50px;
    border-radius: 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .price-block .price-gray-block .price-block-head h1 {
    padding: 15px;
    font-size: 16px;
  }
  .price-block .price-gray-block .price-block-content {
    padding: 15px;
    padding-bottom: 30px;
  }
  .price-block .price-gray-block .price-block-content .list .list-head {
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .name {
    width: 80%;
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .price {
    width: 20%;
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content .message {
    margin-top: 40px;
  }
  .price-block .price-gray-block .price-block-content .message p {
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-foot {
    display: flex;
    justify-content: flex-end;
  }
  .price-block .price-gray-block .price-block-foot .btns-block {
    width: 50%;
  }
  .price-block .price-gray-block .price-block-foot .btns-block .btn-link {
    height: 50px;
    border-radius: 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .price-block .price-gray-block .price-block-head .logo {
    padding-left: 0px;
    width: calc(30% + 15px);
  }
  .price-block .price-gray-block .price-block-head .logo img {
    width: 140px;
  }
  .price-block .price-gray-block .price-block-head h1 {
    width: calc(70% - 15px);
    padding: 15px;
    font-size: 14px;
  }
  .price-block .price-gray-block .price-block-content {
    padding: 15px;
    padding-bottom: 30px;
  }
  .price-block .price-gray-block .price-block-content .list .list-head {
    font-size: 12px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .name {
    width: 70%;
    font-size: 12px;
  }
  .price-block .price-gray-block .price-block-content .list .list-body ul li .price {
    width: 30%;
    font-size: 12px;
  }
  .price-block .price-gray-block .price-block-content .message {
    margin-top: 40px;
  }
  .price-block .price-gray-block .price-block-content .message p {
    font-size: 12px;
  }
  .price-block .price-gray-block .price-block-foot {
    display: flex;
    justify-content: flex-end;
  }
  .price-block .price-gray-block .price-block-foot .btns-block {
    width: 50%;
  }
  .price-block .price-gray-block .price-block-foot .btns-block .btn-link {
    height: 40px;
    border-radius: 20px;
    font-size: 12px;
  }
}
@media screen and (max-width: 399px) {
  .price-block .price-gray-block .price-block-head .logo img {
    width: 100px;
  }
  .price-block .price-gray-block .price-block-head h1 {
    width: calc(70% - 15px);
    padding: 15px;
    font-size: 14px;
  }
}
.container-modal {
  position: relative;
  padding: 20px;
  display: block;
  width: 400px;
  height: 460px;
}
.container-modal .btn-close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1006;
}
.container-modal .btn-close .icon-close {
  width: 26px;
  height: 26px;
  position: relative;
  cursor: pointer;
}
.container-modal .btn-close .icon-close .close-block {
  height: 2px;
  width: 34px;
  background-color: #212112;
  position: absolute;
  transition: ease 0.4s;
}
.container-modal .btn-close .icon-close .close-block:nth-child(1) {
  left: -4px;
  top: 10px;
  transform: rotate(45deg);
}
.container-modal .btn-close .icon-close .close-block:nth-child(2) {
  right: -4px;
  top: 10px;
  transform: rotate(-45deg);
}
.container-modal .head-modal {
  position: relative;
  padding-top: 0px;
}
.container-modal .head-modal .name-modal {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
  z-index: 5;
}
.container-modal .head-modal .text-modal {
  position: relative;
  z-index: 5;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
}
.container-modal .body-modal .container-input {
  margin-bottom: 10px;
  display: flex;
  position: relative;
  z-index: 5;
  align-items: center;
  height: 50px;
  border: 1px solid gray;
  background-color: #fff;
  padding: 0 12px;
}
.container-modal .body-modal .container-input input {
  border: none;
  line-height: 1;
  height: 100%;
  width: 100%;
  padding: 12px 0 12px 10px;
  border-radius: 50px;
  font-size: 16px;
}
.container-modal .body-modal .container-submit {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}
.container-modal .body-modal .container-submit .btn, .container-modal .body-modal .container-submit .btn-modal-close {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  margin: 0 auto;
  font-size: 14px;
  border: none;
  background-color: #414042;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
.container-modal .body-modal .politics {
  margin-bottom: 20px;
}
.container-modal .body-modal .politics label {
  overflow: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container-modal .body-modal .politics label .square {
  width: 16px;
  height: 16px;
  border: 1px solid #414042;
  border-radius: 2px;
  margin-right: 10px;
  position: relative;
  transition: ease 0.4s;
}
.container-modal .body-modal .politics label .square::before {
  content: "";
  position: absolute;
  display: block;
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  width: 6px;
  height: 10px;
  transform: rotate(45deg);
  left: calc(50% - 3px);
  top: calc(50% - 6px);
}
.container-modal .body-modal .politics label .text {
  font-size: 14px;
  line-height: 19px;
  color: #7C7C7C;
}
.container-modal .body-modal .politics label input {
  display: block;
  width: 0;
  height: 0;
  border: none;
  position: absolute;
  left: -999px;
}
.container-modal .body-modal .politics label.checkbox-checked .square {
  background-color: #414042;
}

#modal-success .container-modal, #modal-error .container-modal {
  height: 196px;
}

@media screen and (max-width: 767px) {
  .container-modal {
    padding: 20px;
    display: block;
    width: 450px;
    height: 400px;
  }
  .container-modal .head-modal {
    padding-top: 10px;
  }
  .container-modal .head-modal .name-modal {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .container-modal .head-modal .text-modal {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .container-modal .head-modal .figure {
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
  }
  .container-modal .body-modal .container-input {
    height: 40px;
    margin-bottom: 10px;
  }
  .container-modal .body-modal .container-input input {
    font-size: 14px;
    padding-left: 0;
  }
  .container-modal .body-modal .politics {
    margin-bottom: 20px;
  }
  .container-modal .body-modal .politics label .text {
    font-size: 12px;
    line-height: 14px;
  }
  .container-modal .body-modal .container-submit {
    height: 40px;
  }
  .container-modal .body-modal .container-submit .figure {
    width: 60px;
    height: 60px;
    top: -10px;
    right: -20px;
  }
}
@media screen and (max-width: 575px) {
  .container-modal {
    width: 100%;
  }
  .container-modal .btn-close {
    top: 10px;
    right: 10px;
  }
  .container-modal .btn-close .icon-close {
    width: 18px;
    height: 18px;
  }
  .container-modal .btn-close .icon-close .close-block {
    height: 2px;
    width: 22px;
  }
  .container-modal .btn-close .icon-close .close-block:nth-child(1) {
    left: -2px;
  }
  .container-modal .btn-close .icon-close .close-block:nth-child(2) {
    right: -2px;
  }
}
.first-block {
  padding: 25px 0 10px;
}

.second-block .top-gray-block, .portfolio-block .top-gray-block {
  padding: 35px 0;
}
.second-block .top-gray-block .top-block-foot, .portfolio-block .top-gray-block .top-block-foot {
  display: flex;
  justify-content: space-between;
}
.second-block .top-gray-block .top-block-foot .figure, .portfolio-block .top-gray-block .top-block-foot .figure {
  position: relative;
  width: 50%;
  height: 75px;
  border-radius: 0 0 40px 40px;
  background-color: var(--cl-gray-block);
  top: 140%;
  left: 0;
}
.second-block .top-gray-block .top-block-foot .figure::before, .portfolio-block .top-gray-block .top-block-foot .figure::before {
  content: "";
  position: absolute;
  display: block;
  height: 37px;
  width: 37px;
  background-color: var(--cl-gray-block);
  top: 0px;
  left: 100%;
  z-index: 2;
}
.second-block .top-gray-block .top-block-foot .figure:after, .portfolio-block .top-gray-block .top-block-foot .figure:after {
  content: "";
  position: absolute;
  z-index: 3;
  width: 74px;
  height: 74px;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  top: 0;
  left: 100%;
}
.second-block .top-gray-block .top-block-foot .top-btns-block, .portfolio-block .top-gray-block .top-block-foot .top-btns-block {
  width: calc(50% - 15px);
  margin: 15px 0 0 15px;
}
.second-block .top-gray-block .top-block-foot .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-block-foot .top-btns-block .btn-link {
  position: relative;
  z-index: 3;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: var(--cl-head-block);
}
.second-block .top-gray-block .top-block-content, .portfolio-block .top-gray-block .top-block-content {
  width: 100%;
  position: relative;
  background-color: var(--cl-gray-block);
  border-radius: 40px 40px 40px 0px;
  padding: 40px;
  display: flex;
}
.second-block .top-gray-block .top-block-content .first, .portfolio-block .top-gray-block .top-block-content .first {
  width: 50%;
  padding-right: 30px;
}
.second-block .top-gray-block .top-block-content .first .slider, .portfolio-block .top-gray-block .top-block-content .first .slider {
  width: 100%;
  margin-bottom: -30px;
}
.second-block .top-gray-block .top-block-content .first .slider.slider-tab, .portfolio-block .top-gray-block .top-block-content .first .slider.slider-tab {
  display: none;
}
.second-block .top-gray-block .top-block-content .first .slider.slider-tab.active, .portfolio-block .top-gray-block .top-block-content .first .slider.slider-tab.active {
  display: block;
}
.second-block .top-gray-block .top-block-content .first .slider .slides, .portfolio-block .top-gray-block .top-block-content .first .slider .slides {
  margin-bottom: 0;
  width: 100%;
}
.second-block .top-gray-block .top-block-content .first .slider .slides .slide, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide {
  width: 100%;
}
.second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo {
  border-radius: 35px;
  width: 100%;
  overflow: hidden;
}
.second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img {
  display: block;
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
}
.second-block .top-gray-block .top-block-content .first .slider .control, .portfolio-block .top-gray-block .top-block-content .first .slider .control {
  width: 100%;
  position: absolute;
  z-index: 5;
  top: calc(100% + 30px);
}
.second-block .top-gray-block .top-block-content .first .slider .control .slick-dots, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots {
  position: relative;
  bottom: unset;
}
.second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  width: 15px;
  height: 15px;
}
.second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button {
  display: none;
}
.second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active {
  background-color: rgba(0, 0, 0, 0.5);
}
.second-block .top-gray-block .top-block-content .second, .portfolio-block .top-gray-block .top-block-content .second {
  width: 50%;
  padding-left: 15px;
}
.second-block .top-gray-block .top-block-content .second h1, .portfolio-block .top-gray-block .top-block-content .second h1 {
  font-size: 32px;
  line-height: 1.25;
  margin-bottom: 30px;
}
.second-block .top-gray-block .top-block-content .second h1 span, .portfolio-block .top-gray-block .top-block-content .second h1 span {
  font-weight: 400;
}
.second-block .top-gray-block .top-block-content .second .lists, .portfolio-block .top-gray-block .top-block-content .second .lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.second-block .top-gray-block .top-block-content .second .lists .list-1 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li {
  font-size: 18px;
  margin-bottom: 10px;
}
.second-block .top-gray-block .top-block-content .second .lists .list-1 ul li.tab-btn, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li.tab-btn {
  cursor: pointer;
}
.second-block .top-gray-block .top-block-content .second .lists .list-1 ul li.active, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li.active {
  font-weight: 700;
}
.second-block .top-gray-block .top-block-content .second .lists .list-2 ul, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul {
  display: flex;
  flex-direction: column;
}
.second-block .top-gray-block .top-block-content .second .lists .list-2 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li {
  margin: 8px 0;
}
.second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a {
  display: block;
  width: 40px;
  height: 40px;
}
.second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img {
  width: inherit;
  height: inherit;
  display: block;
}
.second-block .top-gray-block .top-btns-block, .portfolio-block .top-gray-block .top-btns-block {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0 15px;
}
.second-block .top-gray-block .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-btns-block .btn-link {
  margin-bottom: 10px;
  width: calc(50% - 15px);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: var(--cl-head-block);
}
.second-block .text-1, .portfolio-block .text-1 {
  font-size: 20px;
  margin-bottom: 0;
}
.second-block .text-1 span, .portfolio-block .text-1 span {
  text-transform: uppercase;
}
.second-block .text-2, .portfolio-block .text-2 {
  font-size: 20px;
}
.second-block .portfolio-list, .portfolio-block .portfolio-list {
  margin: 25px 0 75px;
  display: none;
}
.second-block .portfolio-list.active, .portfolio-block .portfolio-list.active {
  display: block;
}
.second-block .portfolio-list .portfolio-item, .portfolio-block .portfolio-list .portfolio-item {
  margin-bottom: 12px;
  width: 100%;
  display: block;
}
.second-block .portfolio-list .portfolio-item img, .portfolio-block .portfolio-list .portfolio-item img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}

@media screen and (max-width: 991px) {
  .second-block .top-gray-block, .portfolio-block .top-gray-block {
    padding: 35px 0;
  }
  .second-block .top-gray-block .top-block-foot, .portfolio-block .top-gray-block .top-block-foot {
    display: flex;
    justify-content: space-between;
  }
  .second-block .top-gray-block .top-block-foot .figure, .portfolio-block .top-gray-block .top-block-foot .figure {
    position: relative;
    width: 50%;
    height: 65px;
    border-radius: 0 0 30px 30px;
    background-color: var(--cl-gray-block);
    top: 140%;
    left: 0;
  }
  .second-block .top-gray-block .top-block-foot .figure::before, .portfolio-block .top-gray-block .top-block-foot .figure::before {
    content: "";
    position: absolute;
    display: block;
    height: 37px;
    width: 37px;
    background-color: var(--cl-gray-block);
    top: 0px;
    left: 100%;
    z-index: 2;
  }
  .second-block .top-gray-block .top-block-foot .figure:after, .portfolio-block .top-gray-block .top-block-foot .figure:after {
    content: "";
    position: absolute;
    z-index: 3;
    width: 74px;
    height: 74px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
    top: 0;
    left: 100%;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block, .portfolio-block .top-gray-block .top-block-foot .top-btns-block {
    width: calc(50% - 15px);
    margin: 15px 0 0 15px;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-block-foot .top-btns-block .btn-link {
    position: relative;
    z-index: 3;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .top-gray-block .top-block-content, .portfolio-block .top-gray-block .top-block-content {
    width: 100%;
    position: relative;
    background-color: var(--cl-gray-block);
    border-radius: 30px 30px 30px 0px;
    padding: 20px;
    display: flex;
  }
  .second-block .top-gray-block .top-block-content .first, .portfolio-block .top-gray-block .top-block-content .first {
    width: 50%;
    padding-right: 30px;
  }
  .second-block .top-gray-block .top-block-content .first .slider, .portfolio-block .top-gray-block .top-block-content .first .slider {
    width: 100%;
    margin-bottom: -30px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides, .portfolio-block .top-gray-block .top-block-content .first .slider .slides {
    margin-bottom: 0;
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide {
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo {
    border-radius: 35px;
    width: 100%;
    overflow: hidden;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: contain;
    object-position: center;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control, .portfolio-block .top-gray-block .top-block-content .first .slider .control {
    width: 100%;
    position: absolute;
    z-index: 5;
    top: calc(100% + 10px);
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots {
    position: relative;
    bottom: unset;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 15px;
    height: 15px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button {
    display: none;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .second-block .top-gray-block .top-block-content .second, .portfolio-block .top-gray-block .top-block-content .second {
    width: 50%;
    padding-left: 15px;
  }
  .second-block .top-gray-block .top-block-content .second h1, .portfolio-block .top-gray-block .top-block-content .second h1 {
    font-size: 22px;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  .second-block .top-gray-block .top-block-content .second h1 span, .portfolio-block .top-gray-block .top-block-content .second h1 span {
    font-weight: 400;
  }
  .second-block .top-gray-block .top-block-content .second .lists, .portfolio-block .top-gray-block .top-block-content .second .lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-1 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 7px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul {
    display: flex;
    flex-direction: column;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li {
    margin: 8px 0;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a {
    display: block;
    width: 40px;
    height: 40px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img {
    width: inherit;
    height: inherit;
    display: block;
  }
  .second-block .top-gray-block .top-btns-block, .portfolio-block .top-gray-block .top-btns-block {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 0 15px;
  }
  .second-block .top-gray-block .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-btns-block .btn-link {
    margin-bottom: 10px;
    width: calc(50% - 15px);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .text-1, .portfolio-block .text-1 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .second-block .text-1 span, .portfolio-block .text-1 span {
    text-transform: uppercase;
  }
  .second-block .text-2, .portfolio-block .text-2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .second-block .top-gray-block, .portfolio-block .top-gray-block {
    padding: 25px 0;
  }
  .second-block .top-gray-block .top-block-foot, .portfolio-block .top-gray-block .top-block-foot {
    display: flex;
    justify-content: space-between;
  }
  .second-block .top-gray-block .top-block-foot .figure, .portfolio-block .top-gray-block .top-block-foot .figure {
    position: relative;
    width: 50%;
    height: 65px;
    border-radius: 0 0 25px 25px;
    background-color: var(--cl-gray-block);
    top: 140%;
    left: 0;
  }
  .second-block .top-gray-block .top-block-foot .figure::before, .portfolio-block .top-gray-block .top-block-foot .figure::before {
    content: "";
    position: absolute;
    display: block;
    height: 37px;
    width: 37px;
    background-color: var(--cl-gray-block);
    top: 0px;
    left: 100%;
    z-index: 2;
  }
  .second-block .top-gray-block .top-block-foot .figure:after, .portfolio-block .top-gray-block .top-block-foot .figure:after {
    content: "";
    position: absolute;
    z-index: 3;
    width: 74px;
    height: 74px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
    top: 0;
    left: 100%;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block, .portfolio-block .top-gray-block .top-block-foot .top-btns-block {
    width: calc(50% - 15px);
    margin: 15px 0 0 15px;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-block-foot .top-btns-block .btn-link {
    position: relative;
    z-index: 3;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .top-gray-block .top-block-content, .portfolio-block .top-gray-block .top-block-content {
    width: 100%;
    position: relative;
    background-color: var(--cl-gray-block);
    border-radius: 25px 25px 25px 0px;
    padding: 20px 20px 10px;
    display: flex;
  }
  .second-block .top-gray-block .top-block-content .first, .portfolio-block .top-gray-block .top-block-content .first {
    width: 50%;
    padding-right: 30px;
  }
  .second-block .top-gray-block .top-block-content .first .slider, .portfolio-block .top-gray-block .top-block-content .first .slider {
    width: 100%;
    margin-bottom: -30px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides, .portfolio-block .top-gray-block .top-block-content .first .slider .slides {
    margin-bottom: 0;
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide {
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo {
    border-radius: 20px;
    width: 100%;
    overflow: hidden;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: contain;
    object-position: center;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control, .portfolio-block .top-gray-block .top-block-content .first .slider .control {
    width: 100%;
    position: absolute;
    z-index: 5;
    top: calc(100% + 10px);
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots {
    position: relative;
    bottom: unset;
    line-height: 1;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 8px;
    height: 8px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button {
    display: none;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .second-block .top-gray-block .top-block-content .second, .portfolio-block .top-gray-block .top-block-content .second {
    width: 50%;
    padding-left: 0px;
  }
  .second-block .top-gray-block .top-block-content .second h1, .portfolio-block .top-gray-block .top-block-content .second h1 {
    font-size: 18px;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  .second-block .top-gray-block .top-block-content .second h1 span, .portfolio-block .top-gray-block .top-block-content .second h1 span {
    font-weight: 400;
  }
  .second-block .top-gray-block .top-block-content .second .lists, .portfolio-block .top-gray-block .top-block-content .second .lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-1 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul {
    display: flex;
    flex-direction: column;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li {
    margin: 8px 0;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a {
    display: block;
    width: 30px;
    height: 30px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img {
    width: inherit;
    height: inherit;
    display: block;
  }
  .second-block .top-gray-block .top-btns-block, .portfolio-block .top-gray-block .top-btns-block {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 0 15px;
  }
  .second-block .top-gray-block .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-btns-block .btn-link {
    margin-bottom: 10px;
    width: calc(50% - 15px);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .text-1, .portfolio-block .text-1 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .second-block .text-1 span, .portfolio-block .text-1 span {
    text-transform: uppercase;
  }
  .second-block .text-2, .portfolio-block .text-2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .second-block .top-gray-block, .portfolio-block .top-gray-block {
    padding: 25px 0;
  }
  .second-block .top-gray-block .top-block-foot, .portfolio-block .top-gray-block .top-block-foot {
    display: flex;
    justify-content: flex-end;
  }
  .second-block .top-gray-block .top-block-foot .figure, .portfolio-block .top-gray-block .top-block-foot .figure {
    display: none;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block, .portfolio-block .top-gray-block .top-block-foot .top-btns-block {
    width: 50%;
    margin: -20px 0 0 0;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-block-foot .top-btns-block .btn-link {
    position: relative;
    z-index: 3;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .top-gray-block .top-block-content, .portfolio-block .top-gray-block .top-block-content {
    width: 100%;
    position: relative;
    background-color: var(--cl-gray-block);
    border-radius: 25px;
    padding: 15px 15px 35px;
    display: flex;
    flex-wrap: wrap;
  }
  .second-block .top-gray-block .top-block-content .first, .portfolio-block .top-gray-block .top-block-content .first {
    order: 1;
    width: 100%;
    padding-right: 80px;
  }
  .second-block .top-gray-block .top-block-content .first .slider, .portfolio-block .top-gray-block .top-block-content .first .slider {
    width: 100%;
    margin-bottom: 0px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides, .portfolio-block .top-gray-block .top-block-content .first .slider .slides {
    margin-bottom: 0;
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide {
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo {
    border-radius: 30px;
    width: 100%;
    overflow: hidden;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: contain;
    object-position: center;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control, .portfolio-block .top-gray-block .top-block-content .first .slider .control {
    width: 20%;
    position: absolute;
    z-index: 5;
    top: 0%;
    left: calc(100% + 20px);
    height: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots {
    height: 100%;
    position: relative;
    bottom: unset;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 1;
    width: 12px;
    height: 12px;
    margin: 5px 0;
    flex-shrink: 0;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button {
    display: none;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .second-block .top-gray-block .top-block-content .second, .portfolio-block .top-gray-block .top-block-content .second {
    order: 0;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .second-block .top-gray-block .top-block-content .second h1, .portfolio-block .top-gray-block .top-block-content .second h1 {
    font-size: 18px;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  .second-block .top-gray-block .top-block-content .second h1 span, .portfolio-block .top-gray-block .top-block-content .second h1 span {
    font-weight: 400;
  }
  .second-block .top-gray-block .top-block-content .second .lists, .portfolio-block .top-gray-block .top-block-content .second .lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-1 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul {
    display: flex;
    flex-direction: column;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li {
    margin: 8px 0;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a {
    display: block;
    width: 30px;
    height: 30px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img {
    width: inherit;
    height: inherit;
    display: block;
  }
  .second-block .text-1, .portfolio-block .text-1 {
    font-size: 12px;
    margin-bottom: 0;
  }
  .second-block .text-1 span, .portfolio-block .text-1 span {
    text-transform: uppercase;
  }
  .second-block .text-2, .portfolio-block .text-2 {
    font-size: 12px;
  }
}
@media screen and (max-width: 399px) {
  .second-block .top-gray-block, .portfolio-block .top-gray-block {
    padding: 25px 0;
  }
  .second-block .top-gray-block .top-block-foot, .portfolio-block .top-gray-block .top-block-foot {
    display: flex;
    justify-content: flex-end;
  }
  .second-block .top-gray-block .top-block-foot .figure, .portfolio-block .top-gray-block .top-block-foot .figure {
    display: none;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block, .portfolio-block .top-gray-block .top-block-foot .top-btns-block {
    width: 60%;
    margin: -20px 0 0 0;
  }
  .second-block .top-gray-block .top-block-foot .top-btns-block .btn-link, .portfolio-block .top-gray-block .top-block-foot .top-btns-block .btn-link {
    position: relative;
    z-index: 3;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: var(--cl-head-block);
  }
  .second-block .top-gray-block .top-block-content, .portfolio-block .top-gray-block .top-block-content {
    width: 100%;
    position: relative;
    background-color: var(--cl-gray-block);
    border-radius: 25px;
    padding: 15px 15px 35px;
    display: flex;
    flex-wrap: wrap;
  }
  .second-block .top-gray-block .top-block-content .first, .portfolio-block .top-gray-block .top-block-content .first {
    order: 1;
    width: 100%;
    padding-right: 80px;
  }
  .second-block .top-gray-block .top-block-content .first .slider, .portfolio-block .top-gray-block .top-block-content .first .slider {
    width: 100%;
    margin-bottom: 0px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides, .portfolio-block .top-gray-block .top-block-content .first .slider .slides {
    margin-bottom: 0;
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide {
    width: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo {
    border-radius: 30px;
    width: 100%;
    overflow: hidden;
  }
  .second-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img, .portfolio-block .top-gray-block .top-block-content .first .slider .slides .slide .photo img {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: contain;
    object-position: center;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control, .portfolio-block .top-gray-block .top-block-content .first .slider .control {
    width: 20%;
    position: absolute;
    z-index: 5;
    top: 0%;
    left: calc(100% + 20px);
    height: 100%;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots {
    position: relative;
    bottom: unset;
    flex-direction: column;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 10px;
    height: 10px;
    margin: 5px;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li button {
    display: none;
  }
  .second-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active, .portfolio-block .top-gray-block .top-block-content .first .slider .control .slick-dots li.slick-active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .second-block .top-gray-block .top-block-content .second, .portfolio-block .top-gray-block .top-block-content .second {
    order: 0;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .second-block .top-gray-block .top-block-content .second h1, .portfolio-block .top-gray-block .top-block-content .second h1 {
    font-size: 18px;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  .second-block .top-gray-block .top-block-content .second h1 span, .portfolio-block .top-gray-block .top-block-content .second h1 span {
    font-weight: 400;
  }
  .second-block .top-gray-block .top-block-content .second .lists, .portfolio-block .top-gray-block .top-block-content .second .lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-1 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-1 ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul {
    display: flex;
    flex-direction: column;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li {
    margin: 8px 0;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a {
    display: block;
    width: 30px;
    height: 30px;
  }
  .second-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img, .portfolio-block .top-gray-block .top-block-content .second .lists .list-2 ul li a img {
    width: inherit;
    height: inherit;
    display: block;
  }
}
.contacts-block {
  padding: 25px 0 50px;
}
.contacts-block .gray-form {
  padding: 40px 30px 30px;
  background-color: var(--cl-gray-block);
  border-radius: 40px;
  display: flex;
}
.contacts-block .gray-form form {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.contacts-block .gray-form form input {
  margin-bottom: 8px;
  width: 100%;
  border: 1px solid #fff;
  padding: 12px;
  font-size: 14px;
  height: 50px;
}
.contacts-block .gray-form form input::placeholder, .contacts-block .gray-form form input:placeholder-shown {
  opacity: 1;
  color: #000;
}
.contacts-block .gray-form form textarea {
  border: 1px solid #fff;
  font-size: 14px;
  margin-bottom: 25px;
  width: 100%;
  padding: 12px;
}
.contacts-block .gray-form form textarea::placeholder, .contacts-block .gray-form form textarea:placeholder-shown {
  opacity: 1;
  color: #000;
}
.contacts-block .gray-form form button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cl-head-block);
  color: #fff;
  width: 55%;
  height: 50px;
  border-radius: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.contacts-block .gray-form .info {
  position: relative;
  width: 35%;
  padding-left: 20px;
}
.contacts-block .gray-form .info::before {
  content: "";
  position: absolute;
  display: block;
  width: 50%;
  border-radius: 25px;
  background-color: #414042;
  height: 50px;
  z-index: 2;
  right: 0;
  top: -25px;
}
.contacts-block .gray-form .info::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  border-radius: 25px;
  background-color: #414042;
  height: 50px;
  z-index: 2;
  right: 0;
  top: 50px;
}
.contacts-block .gray-form .info h2 {
  border-radius: 25px;
  border: 2px solid #414042;
  width: 80%;
  text-align: center;
  font-size: 20px;
  padding: 8px 20px;
  background-color: #fff;
  position: relative;
  z-index: 3;
  margin-bottom: 25px;
}
.contacts-block .gray-form .info .links {
  border-radius: 25px;
  border: 2px solid #414042;
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
}
.contacts-block .gray-form .info .links .text-info {
  display: flex;
  flex-direction: column;
}
.contacts-block .gray-form .info .links .text-info .phone {
  height: 18px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.contacts-block .gray-form .info .links .text-info .phone .text {
  font-size: 14px;
  line-height: 1;
}
.contacts-block .gray-form .info .links .text-info .mail {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  height: 18px;
}
.contacts-block .gray-form .info .links .text-info .mail .icon {
  height: 18px;
}
.contacts-block .gray-form .info .links .text-info .mail .icon img {
  width: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.contacts-block .gray-form .info .links .text-info .mail .text {
  font-size: 14px;
  line-height: 1;
}
.contacts-block .gray-form .info .links .text-info .address {
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 1;
  height: 18px;
}
.contacts-block .gray-form .info .links .text-info .site {
  height: 18px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 1;
}
.contacts-block .gray-form .info .links .qr {
  margin-top: 17px;
  width: 80%;
}
.contacts-block .gray-form .info .links .qr img {
  width: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.contacts-block .social {
  margin-top: 30px;
  width: 100%;
}
.contacts-block .social ul {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  width: 100%;
}
.contacts-block .social ul li {
  margin: 0 10px;
}
.contacts-block .social ul li a {
  display: block;
  width: 50px;
  height: 50px;
}
.contacts-block .social ul li a img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 991px) {
  .contacts-block .gray-form form {
    width: 50%;
  }
  .contacts-block .gray-form form button {
    width: 100%;
  }
  .contacts-block .gray-form .info {
    width: 50%;
  }
  .contacts-block .social ul {
    margin: 0;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .contacts-block .gray-form {
    padding: 35px 15px 15px;
    flex-wrap: wrap;
    border-radius: 25px;
  }
  .contacts-block .gray-form form {
    order: 1;
    width: 100%;
  }
  .contacts-block .gray-form form button {
    width: 100%;
  }
  .contacts-block .gray-form .info {
    order: 0;
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .contacts-block .gray-form .info .links {
    padding: 25px 15px 15px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .contacts-block .gray-form .info .links .text-info {
    width: 50%;
  }
  .contacts-block .gray-form .info .links .qr {
    margin-top: 0;
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .contacts-block .gray-form {
    padding: 35px 15px 15px;
    flex-wrap: wrap;
  }
  .contacts-block .gray-form form {
    order: 1;
    width: 100%;
  }
  .contacts-block .gray-form form input {
    height: 40px;
    font-size: 12px;
  }
  .contacts-block .gray-form form button {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }
  .contacts-block .gray-form form textarea {
    font-size: 12px;
  }
  .contacts-block .gray-form .info {
    order: 0;
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .contacts-block .gray-form .info::before {
    height: 40px;
    top: -20px;
  }
  .contacts-block .gray-form .info::after {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
  .contacts-block .gray-form .info .links {
    padding: 25px 15px 15px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .contacts-block .gray-form .info .links .text-info {
    width: 50%;
  }
  .contacts-block .gray-form .info .links .text-info .phone .text {
    font-size: 12px;
  }
  .contacts-block .gray-form .info .links .text-info .mail .text {
    font-size: 12px;
  }
  .contacts-block .gray-form .info .links .text-info .address {
    font-size: 12px;
  }
  .contacts-block .gray-form .info .links .text-info .site {
    font-size: 12px;
  }
  .contacts-block .gray-form .info .links .qr {
    margin-top: 0;
    width: 50%;
  }
  .contacts-block .gray-form .info h2 {
    height: 40px;
    font-size: 12px;
    border-radius: 20px;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 399px) {
  .contacts-block .gray-form .info .links .text-info {
    width: 70%;
  }
  .contacts-block .gray-form .info .links .qr {
    width: 30%;
  }
}