header {
  display: none;
  position: fixed;
  padding: 4px 0;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;

  background-color: var(--cl-bg-page);
  box-shadow: 0px 3px 5px rgba(32, 74, 77, 0.1);
  .logo{
    margin-left: 6px;
    img{
      height: 52px;
    }
  }
  .phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #000;
    border-radius: 50%;
    img{
      width: 52px;
      height: 52px;
      display: block;
    }
  }
  .mobile-menu-icon{
    .icon-menu{
      margin: 11px 6px 11px 0;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      padding: 4px 0;
      .icon-block{
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 6px;
        opacity: 1;
        transition: ease .4s;
        transform: rotate(0);
      }
      &.menu-open{
        .icon-block{
          justify-content: center;
          background-color: #414042;
          &:nth-child(1){
            position: relative;
            top: calc(50% - 2px);
            transform-origin: center;
            transform: rotate(45deg);
          }
          &:nth-child(2){
            opacity: 0;
          }
          &:nth-child(3){
            position: relative;
            bottom: calc(50% - 2px);
            transform-origin: center;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
.mobile-menu{
  top: 0;
  right: -100vw;
  position: fixed;
  z-index: 900;
  width: 320px;
  height: 100dvh;
  padding-top: 60px;
  transition: ease .4s;
  .mobile-menu-container{
    background-color: #fff;
    padding: 18px 6px 12px;
    top: -2px;
    position: relative;
    width: 100%;
    height: calc(100% + 2px);
    box-shadow: 0px 5px 5px rgba(32, 74, 77, 0.1);
    &::before{
      content: '';
      opacity: 0;
      position: absolute;
      width: calc(100dvw - 320px);
      display: block;
      height: 100dvh;
      right: 100%;
      top:0;
      background-color: #1e1e1e;;

    }
    .menu-content-container{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      .menu-name{
        font-weight: 700;
        line-height: 1;
        padding-bottom: 12px;
        border-bottom: 2px solid #0000003d;
        margin-bottom: 6px;
      }
      .menu-content{
        height:calc(100dvh - 28px - 30px - 50px - 14px);
        flex-grow: 1;
        overflow: hidden;
        .menu-content-scroll{
          overflow: auto;
          width: calc(100% + 50px);
          padding-right: 50px;
          padding-left: 6px;
        }
      }
      .menu-control{
        margin-top: 6px;
        border-top: 2px solid #0000003d;
        height: 56px;
        overflow: hidden;
        .btn-close{
          margin-top: 8px;
          cursor: pointer;
          width: 100%;
          height: 36px;
          border-radius: 20px;
          border:2px solid #000;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          font-weight: 700;

        }
      }
    }
  }
}

@media screen and (max-width: 1699px){
  header{

  }
}
@media screen and (max-width: 1499px){
  header{

  }
}
@media screen and (max-width: 1199px){
  header{

  }
}
@media screen and (max-width: 991px){
  header{

    display: block;
  }
}
@media screen and (max-width: 767px){
  header{

  }
}
@media screen and (max-width: 575px){
  header{
    padding: 2px 0;
    .logo{
      img{
        height: 42px;
      }
    }
    .phone{
      margin-top:3px;
      width: 38px;
      height: 38px;
      img{
        width: 42px;
        height: 42px;
      }
    }
    .mobile-menu-icon{
      .icon-menu{
        width: 26px;
        height: 26px;
        margin: 10px 6px 10px 0;
      }
    }
  }
  .mobile-menu{
    padding-top: 50px;
    .mobile-menu-container{
      padding: 12px 6px 6px;
      .menu-content-container{
        .menu-control{
          height: 56px;
        }
      }
    }
  }
}
