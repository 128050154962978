footer{
  margin-top: auto;
  padding: 25px 0;
  .social{
    width: 100%;
    ul{
      display: flex;
      align-items: center;
      margin: 0 -10px;
      width: 100%;
      li{
        margin: 0 10px;
        a{
          display: block;
          width: 50px;
          height: 50px;
          img{
            width: inherit;
            height: inherit;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }

}
@media screen and (max-width: 991px){
  footer{
    .social{
      ul{
        margin: 0;
        justify-content: center;
      }
    }
  }
}