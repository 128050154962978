.price-block{
  padding: 100px 0 50px;
  .price-gray-block{
    .price-block-head{
      display: flex;

      .logo{
        width: calc(45% + 15px);
        position: relative;
        z-index: 3;
        margin: 0 15px 15px 0;
        padding-left: 100px;
        img{
          width: 100%;
          object-fit: contain;
          object-position: center;
          display: block;

        }
      }
      h1{

        position: relative;
        border-radius: 35px 35px 0 0;
        background-color: #e6e7e8;
        padding: 50px 30px 30px;
        width: calc(55% - 15px);
        text-align: right;
        font-size: 26px;
        margin-bottom: 0;
        span{
          text-transform: uppercase;
        }
        &::before{
          content: '';
          position: absolute;
          width: 35px;
          height: 35px;
          display: block;
          right: 100%;
          bottom:0;
          background-color: #e6e7e8;
          z-index: 1;
        }
        &::after{
          content: '';
          position: absolute;
          width: 70px;
          height: 70px;
          display: block;
          right: 100%;
          border-radius: 50%;
          bottom:0;
          background-color: #fff;
          z-index: 2;
        }

      }
    }
    .price-block-content{
      border-radius: 35px 0 35px 35px;
      background-color: #e6e7e8;
      padding: 30px;
      .list{
        margin-bottom: 20px;
        .list-head{
          font-size: 22px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        .list-body{
          ul{
            li{
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              font-size: 16px;
              line-height: 1.2;
              margin-bottom: 10px;

              position: relative;
              &::before{
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                width: 100%;
                border-bottom: 1px dotted rgb(33, 37, 41);
                bottom: 4px;
                left: 0;
                z-index: 1;
              }
              .name{

                width: 60%;
                padding-right: 10px;
                span{
                  position: relative;
                  z-index: 2;
                  display: inline;
                  background-color: #e6e7e8;
                }
              }
              .price{
                width: 10%;
                text-align: right;
                white-space: nowrap;
                span{
                  position: relative;
                  z-index: 2;
                  background-color: #e6e7e8;
                }

              }
            }
          }
        }
      }
      .message{
        margin-top: 40px;
        p{

        }
      }
    }
    .price-block-foot{
      display: flex;
      justify-content: flex-end;
      .btns-block{
        width: 50%;
        .btn-link{
          margin-bottom: 10px;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
          text-align: center;
          background-color: var(--cl-head-block);
          margin-top: -30px;

        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .price-block{
    padding: 50px 0;
    .price-gray-block{
      .price-block-head{
        h1{
          padding: 20px;
          font-size: 22px;
        }
      }
      .price-block-content{
        padding: 20px;
        .list{
          .list-head{
            font-size: 18px;
          }
          .list-body{
            ul{
              li{
                .name{
                  width: 80%;
                  font-size: 14px;
                }
                .price{
                  width: 20%;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .message{
          margin-top: 40px;
          p{
            font-size: 14px;
          }
        }
      }
      .price-block-foot{
        display: flex;
        justify-content: flex-end;
        .btns-block{
          width: 50%;
          .btn-link{
            height: 50px;
            border-radius: 25px;
            font-size: 14px;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .price-block{
    .price-gray-block{
      .price-block-head{
        h1{
          padding: 15px;
          font-size: 16px;
        }
      }
      .price-block-content{
        padding: 15px;
        padding-bottom: 30px;
        .list{
          .list-head{
            font-size: 14px;
          }
          .list-body{
            ul{
              li{
                .name{
                  width: 80%;
                  font-size: 14px;
                }
                .price{
                  width: 20%;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .message{
          margin-top: 40px;
          p{
            font-size: 14px;
          }
        }
      }
      .price-block-foot{
        display: flex;
        justify-content: flex-end;
        .btns-block{
          width: 50%;
          .btn-link{
            height: 50px;
            border-radius: 25px;
            font-size: 14px;

          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .price-block{
    .price-gray-block{
      .price-block-head{
        .logo{

          padding-left: 0px;
          width: calc(30% + 15px);
          img{
            width: 140px;
          }
        }
        h1{
          width: calc(70% - 15px);
          padding: 15px;
          font-size: 14px;
        }
      }
      .price-block-content{
        padding: 15px;
        padding-bottom: 30px;
        .list{
          .list-head{
            font-size: 12px;
          }
          .list-body{
            ul{
              li{
                .name{
                  width: 70%;
                  font-size: 12px;
                }
                .price{
                  width: 30%;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .message{
          margin-top: 40px;
          p{
            font-size: 12px;
          }
        }
      }
      .price-block-foot{
        display: flex;
        justify-content: flex-end;
        .btns-block{
          width: 50%;
          .btn-link{
            height: 40px;
            border-radius: 20px;
            font-size: 12px;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 399px){
  .price-block{
    .price-gray-block{
      .price-block-head{
        .logo{
          img{
            width: 100px;
          }
        }
        h1{
          width: calc(70% - 15px);
          padding: 15px;
          font-size: 14px;
        }
      }
    }

  }
}